// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navigation from './components/Navigation';
import Home from './components/Home';
import About from './components/About';
import Videos from './components/Videos';
import Games from './components/Games';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import './App.css';

function DebugRoute() {
  const location = useLocation();
  return <div>Current path: {location.pathname}</div>;
}

function App() {
  const [loading, setLoading] = useState(false);

  return (
    <Router>
      <div className="container">
        <div className="background-layer"></div>
        <div className="gradient-layer"></div>
        <Navigation setLoading={setLoading} />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/games" element={<Games />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;