import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const SceneContainer = styled.div`
  width: 400px;
  height: 300px;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
`;

const skyGlow = keyframes`
  0%, 100% { opacity: 0.3; }
  50% { opacity: 0.6; }
`;

const waterRipple = keyframes`
  0% { transform: translate(0, 0); }
  50% { transform: translate(-10px, 0); }
  100% { transform: translate(0, 0); }
`;

const lightning = keyframes`
  0%, 100% { opacity: 0; }
  92% { opacity: 0; }
  94%, 96%, 98% { opacity: 1; }
  93%, 95%, 97%, 99% { opacity: 0; }
`;

const Scene = styled.svg`
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #001a33, #004d99);
`;

const Sky = styled.rect`
  animation: ${skyGlow} 4s ease-in-out infinite;
`;

const Water = styled.path`
  animation: ${waterRipple} 3s ease-in-out infinite;
`;

const LightningBolt = styled.path`
  animation: ${lightning} 5s infinite;
`;

const textFloat = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
`;

const WaterText = styled.text`
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: bold;
  fill: rgba(255, 255, 255, 0.8);
  filter: url(#water);
  animation: ${textFloat} 3s ease-in-out infinite;
`;

const LightningWaterEmail = ({ email }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <SceneContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <Scene viewBox="0 0 400 300" preserveAspectRatio="none">
        <defs>
          <radialGradient id="skyGlow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" stopColor="#00ccff" stopOpacity="0.3" />
            <stop offset="100%" stopColor="#004d99" stopOpacity="0" />
          </radialGradient>
          <filter id="water">
            <feTurbulence type="turbulence" baseFrequency="0.01" numOctaves="3" result="turbulence" seed={Math.random()}>
              <animate attributeName="baseFrequency" values="0.01;0.015;0.01" dur="10s" repeatCount="indefinite" />
            </feTurbulence>
            <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="10" result="displacement" />
            <feGaussianBlur in="displacement" stdDeviation="2" result="blur" />
            <feBlend in="SourceGraphic" in2="blur" mode="normal" />
          </filter>
        </defs>
        
        <Sky x="0" y="0" width="400" height="200" fill="url(#skyGlow)" />
        
        <Water d="M0,220 Q100,200 200,220 T400,220 L400,300 L0,300 Z" fill="#006699" opacity="0.7" />
        <Water d="M0,230 Q100,210 200,230 T400,230 L400,300 L0,300 Z" fill="#0088cc" opacity="0.5" />
        
        <LightningBolt d="M200,0 L190,50 L210,60 L180,120 L220,130 L200,200" stroke="#ffffff" strokeWidth="2" fill="none" />
        
        {isHovered && (
          <>
            <WaterText x="200" y="260" textAnchor="middle">Open</WaterText>
            <WaterText x="200" y="290" textAnchor="middle">&amp; Send</WaterText>
          </>
        )}
      </Scene>
    </SceneContainer>
  );
};

const Contact = () => {
  return (
    <div id="contact" className="content">
      <h1>Get in Touch</h1>
      <p>Feel free to reach out for inquiries.</p>
      <LightningWaterEmail email="christopher.bodnarchuk@gmail.com" />
    </div>
  );
};

export default Contact;