import React from 'react';
import HeroSection from './HeroSection';

function Home() {
  return (
    <div id="home" className="content">
      
      <h1>Christopher Bodnarchuk</h1>
      <HeroSection />
      <div></div>
    </div>
  );
}

export default Home;