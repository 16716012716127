// components/Videos.js
import React from 'react';

function Videos() {
  return (
    <div id="videos" className="content">
      <h1>Videos, Film</h1>
      <h2>Watch card games on my MTG Channel</h2>
      <div id="MTGCollection1">
      <div class="YTContainer">
      <iframe
        class="YTPlayer"
        src="https://www.youtube.com/embed/zuRUokmn1Tg?si=ot49sDx3FC_orerD"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
      </div>
      <div class="YTContainer">
      <iframe
        class="YTPlayer"
        src="https://www.youtube.com/embed/nAUDFN4P8VY?si=oMTIOxSDTCA8rr26"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
      </div>
      </div>
    </div>
  );
}

export default Videos;