import React from 'react';

function Portfolio() {
    return (
        <div id="portfolio" className="content">
            <h1>CB CV</h1>
            <section className="profile">
                <h2>Christopher Bodnarchuk</h2>
                <hr className="hr-5" />
                <h3>Software & Web Developer Profile</h3>
                <p> | 204-962-8609 | </p>
                <p>
                    <a href="https://github.com/grendeld" target="_blank" rel="noopener noreferrer">GitHub</a> |{' '}
                    <a href="https://christopherbodnarchuk.com" target="_blank" rel="noopener noreferrer">Website</a>
                </p>
            </section>
            <section class="summary">
                <h3>Profile</h3>
                <p>The best ways to create solutions in a tech-driven world. Supporting teams that deploy quality
                    software/web projects.
                </p>
            </section>

            <section class="skills">
                <div class="skill-category">
                    <h4>Languages</h4>
                    <ul>
                        <li>C#</li>
                        <li>JavaScript/ES6/TypeScript</li>
                        <li>PHP</li>
                        <li>Python</li>
                    </ul>
                </div>
                <div class="skill-category">
                    <h4>Frameworks/Libraries</h4>
                    <ul>
                        <li>React</li>
                        <li>ASP.NET</li>
                        <li>Laravel</li>
                        <li>MVC</li>
                        <li>D3.js</li>
                    </ul>
                </div>
                <div class="skill-category">
                    <h4>Tools</h4>
                    <ul>
                        <li>Visual Studio 2015/2017/2019/2022</li>
                        <li>Git, GitHub; both Desktop and Visual Studio</li>
                        <li>Adobe - XD, Photoshop, Premiere</li>
                        <li>Atom, VS Code</li>
                        <li>Blender</li>
                    </ul>
                </div>
                <div class="skill-category">
                    <h4>Database</h4>
                    <ul>
                        <li>MySQL</li>
                        <li>Microsoft SQL Server</li>
                        <li>SQLite</li>
                    </ul>
                </div>
                <div class="skill-category">
                    <h4>Microsoft</h4>
                    <ul>
                        <li>MS OS</li>
                        <li>MS Office 365 Suite</li>
                        <li>Entra ID</li>
                        <li>MS Admin; Exchange, Defender, Connect, etc.</li>
                        <li>Excel</li>
                    </ul>
                </div>
            </section>

            <section class="experience">
                <h3>Employment History</h3>
                <div class="job">
                    <h4>System Developer and Support</h4>
                    <p>CPA Manitoba, 2021-2024</p>
                    <ul>
                        <li>Project Implementation; Define and lead scope based on requirements, 
                            create project implementation timelines, track project progress, 
                            cooperate with stakeholders and ensure project delivery</li>
                        <li>Develop, maintain websites and web  integrations</li>
                        <li>Database development and support (SQL)</li>
                        <li>Develop & Support database reports using CMS, Power BI, Excel, Custom Templating</li>
                        <li>Create and update technical docs on all systems, Web, Cybersecurity, CMS and User Manuals</li>
                        <li>Provide training on Microsoft OS, Office 365 tools, iMIS, Adobe Products and AI tools</li>
                        <li>Report, monitor and solve bugs/issues</li>
                        <li>Cybersecurity duties: Response, Advanced Threat Prevention (MDO 365), 
                            Identity and Access Management (Entra ID) and Phishing Awareness Training</li>
                        <li>Assist with Cybersecurity Audits and implementation of CIS Controls</li>
                        <li>Provide workstation Support</li>
                        <li>Sever, Network and Firewall Support with Fortigate, Aruba, VMware, Datto Cloud</li>
                        <li>Web Access and HPE/Aruba Acess Point Management</li>
                        <li>Printer support, Canon and Xerox</li>
                        <li>JavaScript</li>
                    </ul>
                </div>
                <div class="job">
                    <h4>Software Developer, Web Content Creator</h4>
                    <p>CB Design, 2017-current</p>
                    <ul>
                        <li>Web and software support for clients. Advising clients on design, application deployment
                            and best practices</li>
                        <li>Extensive definition of scope based on requirements; Statements of Work</li>
                        <li>Manage and create web content for clients using Web Languages, Frameworks and Content
                            Management Systems</li>
                        <li>Develop front/back end solutions with C#, ASP.NET, SASS and JS</li>
                        <li>Create Data Visualizations with JavaScript libraries like D3.js</li>
                        <li>Perform web-hosting duties with cPanel and CMS like Wix and Square Space</li>
                        <li>Mail Server Admin</li>
                        <li>Backend script writing with PHP</li>
                        <li>Photography, Videography and Video Editing services</li>
                    </ul>
                </div>
                <div class="job">
                    <h4>API Developer</h4>
                    <p>JMT Consultants, February 2019 - July 2019</p>
                    <ul>
                        <li>Design and maintain plugin Software for Tekla Tools with Tekla API, C# and extended
                            libraries</li>
                        <li>Build Winforms and WPF desktop reporting tools using XAML, RESTful APIs (Flow API)</li>
                        <li>Develop web applications with HTML/CSS/Javascript, ASP.NET</li>
                        <li>Source control with GIT + Visual Studio</li>
                        <li>Weekly, Daily Reporting, SCRUM and AGILE methods</li>
                        <li>Desktop and IT troubleshooting</li>
                        <li>Create and deploy native Reporting ASP.NET applications with Excel file output</li>
                    </ul>
                </div>
            </section>

            <section class="education">
                <h3>Education</h3>
                <div class="degree">
                    <h4>Diploma in Software and Database Development</h4>
                    <p>2016-2017, Robertson College, Winnipeg, MB</p>
                </div>
                <div class="degree">
                    <h4>Certificate in Arts Management</h4>
                    <p>2011-2012, University of Windsor, Windsor, ON</p>
                </div>
            </section>
            <section class="education"></section>
            <h3>Awards</h3>
            <div class="degree">
                <h4>iMIS Great Things Award - w/ CPA Manitoba - 2023</h4>
                <p>The iMIS Great Things Awards celebrate and recognize iMIS users who are using iMIS more, using it
                    better and getting greater value from the platform.
                    Awarded monthly ASI reviews countless submissions from the iMIS community, sharing stories on
                    the
                    great things their peers, colleagues, and partners are achieving.</p>
            </div>
        </div >
    );
}

export default Portfolio;